/* You can add global styles to this file, and also import other style files */
@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
}

@import '@nebular/theme/styles/theming';

@import './app/style-guide/index';
@import './styles/shared';
@import '@afaqylibs/ngx-picker/theming';

.cdk-overlay-container {
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;

  .cdk-overlay-connected-position-bounding-box {
    & .cdk-overlay-pane {
      &.owl-dt-popup {
        .owl-dt-container {
          font-size: 0.7rem !important;

          ::ng-deep {
            .owl-dt-timer-box {
              height: 45px;
            }
          }

          .owl-dt-container-inner {
            & .owl-dt-container-row {
              &.owl-dt-timer {
                padding-bottom: 1.15em;

                & .owl-dt-timer-box {
                  height: 45px !important;
                }
              }

              &.owl-dt-calendar {
                .owl-dt-calendar-main {
                  .owl-dt-calendar-view {
                    .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
                      background-color: #42428a !important;
                    }

                    .owl-dt-calendar-table {
                      .owl-dt-calendar-cell-selected {
                        background-color: #42428a !important;
                      }

                      &.owl-dt-calendar-month-table {
                        .owl-dt-calendar-body {
                          tr {
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .owl-dt-container-info .owl-dt-container-info-active {
              color: #42428a !important;
            }

            .owl-dt-container-buttons {
              color: #42428a !important;
            }
          }
        }
      }
    }
  }
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 9999999 !important;
}

.device-tiered-menu {
  // ::ng-deep {
  p-tieredmenusub {
    .p-tieredmenu-root-list {
      .p-menuitem {
        background: white;

        .p-menuitem-content {
          .p-menuitem-link {
            text-decoration: none;
          }
        }

        &:not(.p-highlight):not(.p-disabled) {
          &.p-focus {
            > .p-menuitem-content {
              background: white;

              &:hover {
                background: #e9ecef;
              }
            }
          }
        }
      }
    }
  }

  // }
}

.room-tiered-menu {
  // ::ng-deep {
  p-tieredmenusub {
    .p-tieredmenu-root-list {
      .p-menuitem {
        background: white;

        .p-menuitem-content {
          .p-menuitem-link {
            text-decoration: none;
          }
        }

        &:not(.p-highlight):not(.p-disabled) {
          &.p-focus {
            > .p-menuitem-content {
              background: white;

              &:hover {
                background: #e9ecef;
              }
            }
          }
        }
      }
    }
  }

  // }
}

.room-chart-sidebar {
  height: 550px;
}

.device-chart-sidebar {
  height: 500px;
}

/* You can add global styles to this file, and also import other style files */
.vh100 {
  height: 100vh;

  @include for-phone-only {
    height: -webkit-fill-available !important;
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .vh100 {
    height: -webkit-fill-available;
  }
}

:root {
  --menu-flex: 1;
}
